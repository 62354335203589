<template>
  <div class="label">
    <row>
      <div class="label-container">
        <shipmentnumber-header :number="shipmentNumber" />
        <!-- <label-image url="images/labels/label.png" /> -->
        <div style="margin-bottom: 15px" v-for="pack in packages" :key="pack.packLine">
            <a 
                :href="'https://engine.bftlogistik.dk:7000/partner/v1/getlabel/'+mytoken+'/'+shipmentNumber+'/'+pack.packTT" target="_blank">
                <btn 
                    :label="'Package-'+pack.packLine"
                    width="200px"
                    left
                />
            </a>
        </div>
        <div class="label-actions">
          <div class="label-actions__item label-actions__item--back">
            <btn label="Tilbage" width="100%" outline @click="backClick" />
          </div>
          <!-- <div class="label-actions__item label-actions__item--print">
            <btn label="Print label" width="100%" right @click="printClick" />
          </div> -->
        </div>
      </div>
    </row>
  </div>
</template>

<script>
    import Row from "@/components/Row";
    import ShipmentnumberHeader from "@/components/ShipmentnumberHeader";
    //import LabelImage from "@/components/LabelImage";
    import Btn from "@/components/Btn";
    //import axios from "axios";

    export default {
        name: "Label",
        components: {
            Row,
            ShipmentnumberHeader,
            //LabelImage,
            Btn,
        },
        data() {
            // Dummy data
            return {
            shipmentNumber: "",
            packages : [],
            mytoken : ""
            };
        },
        created(){
            var shipmentdata = this.$route.params.packageresponse;
            if(shipmentdata == undefined){
                this.$router.replace("/create");    
            }else{
                console.log(shipmentdata);
                this.mytoken = localStorage.getItem("token");
                this.shipmentNumber =  shipmentdata.shipmentID;
                this.packages = shipmentdata.packages;
            }
        },
        methods: {
            backClick() {
                console.log("Back click");
                this.$router.replace("/create");
            },
            printClick() {
                console.log("Print click");
            },
            // getPDF(data){
            //     console.log(data);
            //     this.mytoken = localStorage.getItem("token");
            //     var config = {
            //         method: "get",
            //         url: "https://engine.bftlogistik.dk:7000/partner/v1/getlabel/" +this.mytoken +
            //         '/' + this.shipmentNumber + '/' + data
            //         ,
            //         headers: {
            //         "Content-Type": "application/json",
            //         }
            //     };
            //     axios(config)
            //     .then((response) => {
            //         console.log(response.data);
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //         alert("PDF not found.");
            //     });
            // }
        },
    };
</script>

<style>
.label-container {
  padding: 30px 0;
  max-width: 812px;
  margin: 0 auto;
}
.label-actions {
  margin-top: 30px;
  position: relative;
}
.label-actions__item {
  margin-bottom: 10px;
}

@media screen and (min-width: 600px) {
  .label-actions {
    display: flex;
  }
  .label-actions__item.label-actions__item--back {
    width: 20%;
  }
  .label-actions__item.label-actions__item--print {
    width: 80%;
    padding-left: 10px;
  }
}
</style>
