<template>
  <div class="ordernumber-header">
    forsendelsesnummer <span class="ordernumber-header__number">{{ number }}</span>
  </div>
</template>

<script>
export default {
  name: "ShipmentNumberHeader",
  props: {
    number: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.ordernumber-header {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 20px;
}
.ordernumber-header__number {
  color: #df6138;
  font-weight: bold;
}
</style>
